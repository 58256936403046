<template>
  <v-container class="pa-3">
    <v-card width="500" flat class="mx-auto">
      <v-card class="darkOrange mb-6 mt-1">
        <v-btn
          fab
          @click="shopview = !shopview"
          v-if="!shopview"
          absolute
          width="14.6%"
          height="23%"
          class="transparent pulse"
          style="right: 42.6%; bottom: 17.2%;"
        >
        </v-btn>
        <v-img src="https://251317.selcdn.ru/oboi.ru/coupons/hat.svg"> </v-img>
        <div v-if="shopview">
          <div class="pa-4">
            <div class="my-3">
              <div v-for="shop in region_shop" :key="shop.store">
                <div class="text-22 white--text my-n1">
                  {{ shop.address }}
                </div>
              </div>
            </div>

            <div v-for="shop in other_shop" :key="shop.store">
              <div class="text-14 white--text">
                {{ shop.address }}
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <!-- отключено -->
      <div v-if="false" class="text-18 mb-8 pa-1 orange white--text">
        <div class="my-2 mb-3 text-center">Уважаемые клиенты!</div>
        <div class="my-2">
          Бонусная программа <a href="oboi.ru">OBOI.RU</a> временно
          приостановлена.
        </div>
        <div class="my-2">
          Приносим свои извинения за доставленные неудобства. О возобновлении
          программы мы обязательно оповестим Вас дополнительно.
        </div>
        <!-- <div class="my-2">
             О возобновлении программы мы обязательно оповестим Вас дополнительно.
             </div> -->
      </div>
      <!-- отключено конец -->

      <v-card
        v-for="coupon in coupons.filter(el => svg_template[el.event])"
        class="mb-3 transparent"
        :key="coupon.key"
      >
        <v-btn
          fab
          @click="
            !coupon._send && coupon.status == 'registered'
              ? send_req(coupon.key)
              : '';
            send(coupon);
          "
          :color="
            coupon._send || coupon.status != 'registered'
              ? 'darkBlue'
              : 'transparent'
          "
          absolute
          width="14.3%"
          height="33%"
          :class="coupon._send || coupon.status != 'registered' ? '' : 'pulse'"
          :style="
            coupon._send || coupon.status != 'registered' ? 'opacity: 0.4;' : ''
          "
          style="right: 9.3%; bottom: 10%"
        >
        </v-btn>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img :src="svg_template[coupon.event].src">
              <v-expand-transition>
                <v-overlay
                  v-if="open_key || hover || coupon.status != 'registered'"
                  absolute
                  opacity="1"
                  color="darkBlue"
                  style="right: 50%; box-shadow: 0px 1px white;"
                >
                  <div
                    :style="
                      coupon.status != 'registered'
                        ? 'text-decoration: line-through;'
                        : ''
                    "
                    class=" text-48 white--text font-atkinson"
                    :class="
                      coupon.status != 'registered'
                        ? 'grey--text'
                        : 'white--text'
                    "
                  >
                    {{ coupon.key }}
                  </div>
                </v-overlay>
              </v-expand-transition>
            </v-img>
          </template>
        </v-hover>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Coupon",
  data: () => ({
    shopview: false,
    svg_template: {
      "от дизайнеров или строителей": {
        src:
          "https://251317.selcdn.ru/oboi.ru/coupons/%D0%BA%D1%83%D0%BF%D0%BE%D0%BD.%D0%BA%D0%BB%D0%B5%D0%B9%20%D0%B7%D0%B0%20%D1%80%D1%83%D0%B1%D0%BB%D1%8C.svg"
      },
      null: {
        src:
          "https://251317.selcdn.ru/oboi.ru/coupons/%D0%BA%D1%83%D0%BF%D0%BE%D0%BD.5%20%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D0%BD%D1%82%D0%BE%D0%B2%20%D0%BE%D1%82%205000.svg"
      }
    },

    coupons: [],
    mobile: false,

    open_key: false,

    navigator_text: {
      "от дизайнеров или строителей": "клей в подарок при покупке от 4 рулонов",
      null: "OBOI.RU дарит скидку 5% на покупки от 5000р"
    },

    shop_cards: []
  }),

  computed: {
    region() {
      return [...new Set(this.coupons.map(el => el.st2_user.slice(0, 2)))];
    },

    region_shop() {
      return this.shop_cards
        .filter(
          el =>
            this.region.indexOf(el.fias_code.slice(0, 2)) != -1 &&
            el.store.split("@")[0] != "374"
        )
        .sort((a, b) => (a.address > b.address ? 1 : -1));
    },

    other_shop() {
      return this.shop_cards
        .filter(
          el =>
            this.region.indexOf(el.fias_code.slice(0, 2)) == -1 &&
            el.store.split("@")[0] != "374"
        )
        .sort((a, b) => (a.address > b.address ? 1 : -1));
    }
  },

  methods: {
    async key_info(keys) {
      // получение инфы по купонам
      // .post("http://10.55.55.67:9032/coupon/key", {
      if (keys.length == 0) return;
      return axios
        .post("coupon/key", {
          key: keys
        })
        .then(res => res.data)
        .then(json => (this.coupons = json.response));
    },

    async shop_info() {
      // получение инфы по магазинам
      return axios
        .post("https://api.oboi.ru/v3/store", {})
        .then(res => res.data)
        .then(json => (this.shop_cards = json.response));
    },

    parse_keys() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri).get("c");
      return params ? params.split("-") : [];
    },

    async send_req(key) {
      // отправили купон
      return axios.post("coupon/send", {
        upd: { key: [key] }
      });
    },

    device() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.mobile = true;
      }
    },

    send(coupon) {
      let link = window.location.origin + "/?c=" + coupon.key;
      if (!navigator.share) {
        let tmp = document.createElement("INPUT");
        let focus = document.activeElement;
        tmp.value = link;
        document.body.appendChild(tmp);
        tmp.select();
        document.execCommand("copy");
        document.body.removeChild(tmp);
        focus.focus();
      } else {
        navigator.share({
          title: "OBOI.RU",
          text: this.navigator_text[coupon.event] || "",
          url: link
        });
      }
    }
  },

  created: function() {
    this.key_info(this.parse_keys());
    this.device();
    this.shop_info();
    setTimeout(() => (this.open_key = true), 500);
    setTimeout(() => (this.open_key = false), 800);
  }
};
</script>
<style>
/* @import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&family=Marmelad&family=Philosopher&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&display=swap");

.font-atkinson {
  font-family: "Atkinson Hyperlegible", sans-serif;
}

.pulse {
  background: #f7a600;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.8);
  animation: pulse 1.5s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.8);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>
