import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        orangeMain: "#FFAA00",
        orangeDark: "#F39314",
        blueMain: "#383F61",
        blueGrey: "#686F8A",
        blueLight: "#868EAA",
        blueDark: "#2D324B",
        redLight: "#FF8282",
        greenLight: "#6FDE00",
        grey4: "#BDBDBD",
        grey5: "#E0E0E0",
        grey6: "#F2F2F2",
        grey7: "#FBFBFB",
        darkBlue: "#37474F",
        darkOrange: "#F7A600"
      }
    }
  }
});
