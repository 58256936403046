<template>
  <v-app>
    <v-main>
      <Coupon />
    </v-main>
  </v-app>
</template>

<script>
import Coupon from "./components/Coupon";

export default {
  name: "App",

  components: {
    Coupon
  },

  data: () => ({
    //
  })
};
</script>
